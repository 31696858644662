import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs-observable';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  activeNav:BehaviorSubject<string> = new BehaviorSubject('home');
  isNavtoggle:BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() { }
  navActive(event) {
		this.activeNav.next(event);
    this.isNavtoggle.next(false);
	}
}
