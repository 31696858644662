import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Injectable, EventEmitter, Inject } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';


@Injectable({providedIn: 'root'})
export class AppService {

  constructor(private http:Http, private router:Router, private notification:NotificationsService) { }
  auth;
  urlBase = environment.apiUrl;
  setAuthToken() {
    this.auth = localStorage.getItem('token');
  }
  get(url) {
    this.setAuthToken();
    const headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'token': this.auth });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.urlBase + url, options)
      .map((res: Response) => {
        var data = res.json();
        return data;
      }).catch((error: any) => {
        if(JSON.parse(error['_body']).message == "Invalid access token!"){
          localStorage.clear();
          this.notification.warn("Invalid","Invalid Access Token!");
          this.router.navigateByUrl('');
          return Observable.throw(error.json());
        }
        return Observable.throw(error.json())
      });
  }
  post(url, data) {
    this.setAuthToken();
    var headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'token': this.auth });
    var options = new RequestOptions({ headers: headers });
    return this.http.post(this.urlBase + '' + url, data, options)
      .map((res: Response) => {
        var postRes = res.json();
        postRes.request = data;
        return postRes;
      })
      .catch((error: any) => {
        if(JSON.parse(error['_body']).message == "Invalid access token!"){
          localStorage.clear();
          this.notification.warn("Invalid","Invalid Access Token!");
          this.router.navigateByUrl('');
          return Observable.throw(error.json());
        }
        error = error.json();
        error.request = data;
        return Observable.throw(error);
      });
  }

  uploadCloudinaryImage(url,formData) {
    return this.http.post(this.urlBase + url,formData).map((res: Response) => {
      var postRes = res.json();
      return postRes;
    }) .catch((error: any) => {
      let errorDetail = {error: error.json(), status: error.status};
      return Observable.throw(errorDetail);
    });
  }
    
}
