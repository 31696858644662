import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from "swiper";
import Typewriter from "typewriter-effect/dist/core";
import { ApiService } from "../api.service";
import { CommonService } from "../services/common.service";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { StorageService } from "../services/storage.service";


Swiper.use([Autoplay, Navigation, Pagination, Thumbs]);
@Component({
  selector: "app-front-page",
  templateUrl: "./front-page.component.html",
  styleUrls: ["./front-page.component.scss"],
})
export class FrontPageComponent implements OnInit {
  currentYear = new Date().getFullYear();
  url = this.router.url.split("/")[1];
  totalYears = new Date().getFullYear() - 2015;
  caseStudy = [];
  ourClients = [];
  ourManagers = [];
  
  constructor(
    public router: Router,
    private api: ApiService,
    public common: CommonService,
    private http: HttpClient,
  ) {
   
  }
  getcaseStudies() {
    this.http
      .get(environment.apiUrl + "case-studies?status="+true)
      .subscribe((res: any) => {
        this.caseStudy = res;
 })
      };
  getourClients() {
      this.http
      .get(environment.apiUrl + "our-clients?status="+true)
      .subscribe((res: any) => {
        this.ourClients = res;
         })
      };
  getourManagers() {
      this.http
      .get(environment.apiUrl + "our-managers")
      .subscribe((res: any) => {
        this.ourManagers = res;
         })
      };
  showLoader = false;
  mailSentMessage = "";
  error = {
    name: false,
    email: false,
    invalidEmail: false,
    phone: false,
    message: false,
  };
  contact = {
    name: null,
    email: null,
    phone: null,
    message: null,
  };

  lable = [
    { name: "Angular", icon: "../../assets/img/icon/angular-brands.svg" },
    { name: "React", icon: "../../assets/img/icon/react-brands.svg" },
    { name: "Vue", icon: "../../assets/img/icon/vuejs.png" },
    { name: "Javascript", icon: "../../assets/img/icon/js.webp" },
    { name: "Jquery", icon: "../../assets/img/icon/jquerry.png" },
    { name: "Ionic", icon: "../../assets/img/icon/ionic.png" },
    { name: "Node Js", icon: "../../assets/img/icon/nodejs.svg" },
    { name: "Express Js", icon: "../../assets/img/icon/nodejs.svg" },
    { name: "Html 5", icon: "../../assets/img/icon/html-5.svg" },
    { name: "Bootstrap", icon: "../../assets/img/icon/bootstrap.svg" },
    { name: "Css3", icon: "../../assets/img/icon/css-3.svg" },
    { name: "Saas", icon: "../../assets/img/icon/sass.svg" },
    { name: "PHP", icon: "../../assets/img/icon/php.png" },
    { name: "Codeigniter", icon: "../../assets/img/icon/codeigniter.png" },
    { name: "Laravel", icon: "../../assets/img/icon/Laravel.png" },
    { name: "Slim", icon: "../../assets/img/icon/slim.png" },
    { name: "CakePHP", icon: "../../assets/img/icon/cakephp.png" },
    { name: "Python", icon: "../../assets/img/icon/python.png" },
    { name: "Java", icon: "../../assets/img/icon/js.webp" },
    { name: "Dart", icon: "../../assets/img/icon/dart.png" },
    { name: "Android", icon: "../../assets/img/icon/android.svg" },
    { name: "iOS", icon: "../../assets/img/icon/apple.png" },
    { name: "Flutter", icon: "../../assets/img/icon/flutter.webp" },
    { name: "Wordpress", icon: "../../assets/img/icon/wordpress.webp" },
    { name: "MySQL", icon: "../../assets/img/icon/mysql.png" },
    { name: "MSSQL", icon: "../../assets/img/icon/mssql.png" },
    { name: "PostgreSQL", icon: "../../assets/img/icon/Postgresql.svg" },
    { name: "Mongodb", icon: "../../assets/img/icon/mongodb-logo.png" },
    { name: "Robomongo", icon: "../../assets/img/icon/robomongo.png" },
    { name: "Datagrip", icon: "../../assets/img/icon/datagrip.png" },
    { name: "Firebase", icon: "../../assets/img/icon/firebase.png" },
    { name: "AWS", icon: "../../assets/img/icon/aws.png" },
    { name: "Paypal", icon: "../../assets/img/icon/paypal.png" },
    { name: "Stripe", icon: "../../assets/img/icon/stripe.webp" },
    { name: "Bitcoin", icon: "../../assets/img/icon/bitcoin.webp" },
    { name: "Ethereum", icon: "../../assets/img/icon/ethereum.png" },
    { name: "Perfect Money", icon: "../../assets/img/icon/perfectMoney.png" },
    { name: "Litecoin", icon: "../../assets/img/icon/Litecoin.png" },
  ];

  //   { logo_img: "../../../assets/img/samesizelogo/logo-11.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-01.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-08.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-06.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-09.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-07.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-05.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-04.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-10.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-13.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-15.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-02.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-03.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-12.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-14.png" },
  //   { logo_img: "../../../assets/img/samesizelogo/logo-16.png" },
  // ];


  // caseDetails = [
  //   {
  //     img_box: "../../../assets/img/mockups/web-mockup.png",
  //     title:
  //       "Dawaa Dost is a leading omni-channel pharmacy retail chain for branded generic medicines",
  //     paragraph: [
  //       "Dawaa Dost is a leading omni-channel pharmacy retail chain for branded generic medicines, which is on a mission to change the consumer healthcare journey of a billion-plus population of India through its innovative, deep tech-based, large-scale and affordable solutions.",
  //     ],
  //   },
  //   {
  //     img_box: "../../../assets/img/mockups/web-mockup1.png",
  //     title: "Customer support application to handle all support opeartions of electricity consumers.",
  //     paragraph: [
  //       "A complete support portal for cunsomer to register their complaints related to billing, accounts and other issues. Consumer can book thier digital appointments through the system and get connected with support agents to discuss thier problems through messages, live audio and video call.",
  //     ],
  //   },
  //   {
  //     img_box: "../../../assets/img/mockups/web-mockup2.png",
  //     title: "Waveable, transform your cherished memories into saoundwave art.",
  //     paragraph: [
  //       "Discover your favourite memories related to a songs to be converted into soundwave potrait art, design and style your art according to your liking and have it printed in seconds. And have it played by just sacning your potrait of wavelength by any android app",
  //     ],
  //   },
  //   {
  //     img_box: "../../../assets/img/mockups/web-mockup3.png",
  //     title:
  //       "Medwiki, a knowledge providing system for health and medicines treatments",
  //     paragraph: [
  //       " this website deliver authentic information related to medicines, patients can aquire details reagarding medicines dosage, thier uses and get thier queries of treatment answered by pharmacists. we built this website using our authnetic infrastructure to provide user finest and fastest experience.",
  //     ],
  //   },
  //   // {
  //   //   img_box: "../../../assets/img/mockups/web-mockup5.png",
  //   //   title: "Karma Dost",
  //   //   paragraph: [
  //   //     "Dawaa Dost is a leading omni-channel pharmacy retail chain for branded generic medicines, which is on a mission to change the consumer healthcare journey of a billion-plus population of India through its innovative, deep tech-based, large-scale and affordable solutions.",
  //   //   ],
  //   // },
  //   {
  //     img_box: "../../../assets/img/mockups/myArt.png",
  //     title: "My Art Block, Protect your Artwork using the Blockchain Technology.",
  //     paragraph: [
  //       `Myartblock offers artists and collectors an easy means for securing thier artwork from forgery. They provide certification based on block chain technology to secure your artwork's originality and safe method transfering the ownership between artist and collector`,
  //     ],
  //   },
  // ];

  galleryThumbs;
  galleryTop;
  flags = {
    'Saudia Arabia' : 'https://www.worldometers.info/img/flags/small/tn_sa-flag.gif',
    'Pakistan' : 'https://www.worldometers.info/img/flags/small/tn_pk-flag.gif',
    'India' : 'https://www.worldometers.info/img/flags/small/tn_in-flag.gif',
    'Nigeria' : 'https://www.worldometers.info/img/flags/small/tn_ni-flag.gif', 
    'United Kingdom' : 'https://www.worldometers.info/img/flags/small/tn_uk-flag.gif',
    'United States' : 'https://www.worldometers.info/img/flags/small/tn_us-flag.gif',
    'Kuwait' : 'https://www.worldometers.info/img/flags/small/tn_ku-flag.gif',
    'Canada' : 'https://www.worldometers.info/img/flags/small/tn_ca-flag.gif',
    'Japan' : 'https://www.worldometers.info/img/flags/small/tn_ja-flag.gif',
    'Australia' : 'https://www.worldometers.info/img/flags/small/tn_as-flag.gif',
  }
  industry = [
    {
      icon: "assets/img/education.svg",
      color: "#fbb932",
      title: "Education",
      description:
        "ERPs for educational oragnizations, student awareness programs, online education portals.",
    },
    {
      icon: "assets/img/logistic.svg",
      color: "#fbb932",
      title: "Logictics",
      description:
        "Enabling logistics and supply chain companies to leverage emerging technologies for better real-time fleet management, streamlined communication and improved customer service. ",
    },
    {
      icon: "assets/img/customer.svg",
      color: "#fbb932",
      title: "Customer Support",
      description:
        "Complete user support portal for complaints and problems, contacting agents through live video calls features.",
    },
    {
      icon: "assets/img/health.svg",
      color: "#fbb932",
      title: "Healthcare",
      description:
        "Medicinals statistics, Health care issues, user portal for treatments and medicies dosages.",
    },
    {
      icon: "assets/img/retail.svg",
      color: "#fbb932",
      title: "Retail",
      description:
        "E.Marts websites,Online Medicines stores,feasilble cash payments, secure selling and buying programs.",
    },
    {
      icon: "assets/img/startup.svg",
      color: "#fbb932",
      title: "Startups & Marketplaces",
      description:
        "Blockchain, virtual reality, transportation, delivery, logistics, corporate wellness and construction.",
    },
  ];
  ngOnInit() {
    this.client();
    this.caseSwiper();
    this.getourManagers();
    this.getcaseStudies();
    this.getourClients();
    new Typewriter("#typewriter", {
      strings: [
        "High Performing Apps",
        "Custom Enterprise Solutions",
        "Top Notch Quality Products",
      ],
      autoStart: true,
      cursor: "",
      loop: true,
    });
  }
  navToggle() {
    this.common.isNavtoggle.next(!this.common.isNavtoggle.value);
  }


  showError(key) {
    if (this.contact[key] == null || this.contact[key] == "")
      this.error[key] = true;
    else this.error[key] = false;

    if (key == "email") {
      this.error.invalidEmail =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
          this.contact.email
        )
          ? false
          : true;
    }
  }

  sendMail() {
    this.error.name =
      this.contact.name != "" && this.contact.name != null ? false : true;
    this.error.email =
      this.contact.email != "" && this.contact.email != null ? false : true;
    this.error.phone =
      this.contact.phone != "" && this.contact.phone != null ? false : true;
    this.error.message =
      this.contact.message != "" && this.contact.message != null ? false : true;
    if (
      this.error.name != true &&
      this.error.email != true &&
      this.error.phone != true &&
      this.error.message != true &&
      this.error.invalidEmail != true &&
      this.showLoader != true
    ) {
      this.showLoader = true;
      this.api.sentMail(this.contact).subscribe(
        (mailSent) => {
          this.showLoader = false;
          if (mailSent.status == "OK") {
            this.contact.name = null;
            this.contact.email = null;
            this.contact.phone = null;
            this.contact.message = null;
            this.mailSentMessage =
              "Success! Thank you for your interest with us, We have recieved your application and someone from us will get back to you shortly";
          } else {
            this.mailSentMessage = "Internal Server Error";
          }
        },
        (err) => {
          this.showLoader = false;
          this.mailSentMessage = "Internal Server Error";
        }
      );
    }
  }

  caseSwiper() {
    setTimeout(() => {
      var swiper1 = new Swiper(".mockups-slider", {
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 2500,
          // disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        breakpoints: {
          0: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
        },
      });
    }, 1000);
  }
  client() {
    setTimeout(() => {
      var swiper2 = new Swiper(".client-swiper", {
        spaceBetween: 30,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1025: {
            slidesPerView: 4,
          },
        },
      });
    }, 1000);
  }
}
