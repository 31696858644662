import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  form!: FormGroup;
  isSubmit: boolean = false;
  mailSend: boolean = false;
  isLoading: boolean = false;
  constructor(private fb: FormBuilder, private http: HttpClient) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phoneNo: [null, [Validators.required]],
      Ipaddress: [""],
      message: [""],
    });
  }

  ngOnInit() {

  }
  sendEmail(data) {
    this.http
      .post(environment.apiUrl + "send-mail", data)
      .subscribe((res: any) => {
        this.form.reset();
        this.form.enable()
        this.mailSend = true;
        this.isLoading = false;
        setTimeout(() => {
          this.mailSend = false;
        }, 3000);
      });

  }
  submitForm() {
    this.isLoading = true;
    if (this.form.valid) {
      let data = this.form.value;
      this.form.disable()
      this.isSubmit = false;
      this.http
        .get("https://api.ipify.org/?format=json")
        .subscribe((res: any) => {
          if (res) {
            data = { ...data, Ipaddress: res.ip };
            this.sendEmail(data);
          } else {
            this.sendEmail(data);
          }
        });
    } else {
      this.isSubmit = true;
      this.isLoading = false;
    }
  }
}
