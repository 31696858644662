import {  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Maksof';

  constructor(private storageService:StorageService) {
    const currentVersion = this.storageService.get('version')
    if(currentVersion != environment.version){
      this.storageService.cleanAll();
      this.storageService.set('version',environment.version);
      window.location.reload();
    }
  }
}
