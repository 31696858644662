import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private appService:AppService) { }

	sentMail(obj){
		return this.appService.post(`server/postContact2.php`,obj);
	}
}
