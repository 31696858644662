import { Injectable } from "@angular/core";

export type STORAGE_TYPE = 'LOCAL' | 'COOKIE' | 'SESSION';

@Injectable({
    providedIn: 'root'
  })
  export class StorageService {
    constructor() { }
    set(key: string, data: any, type: STORAGE_TYPE = 'LOCAL') {
        switch (type) {
          case 'LOCAL':
            localStorage.setItem(key, JSON.stringify(data));
            break;
          case 'SESSION':
            sessionStorage.setItem(key, JSON.stringify(data));
            break;
        }
      }
    
      get(key: string, type: STORAGE_TYPE = 'LOCAL') {
        switch (type) {
          case 'LOCAL':
            return JSON.parse(localStorage.getItem(key)! || "null");
          case 'SESSION':
            return JSON.parse(sessionStorage.getItem(key)! || "null");
    
        }
      }
    
      clear(key: string, type: STORAGE_TYPE = 'LOCAL') {
        switch (type) {
          case 'LOCAL':
            localStorage.removeItem(key);
            break;
          case 'SESSION':
            sessionStorage.removeItem(key);
            break;
        }
      }
    
      cleanAll(type: STORAGE_TYPE = 'LOCAL') {
        switch (type) {
          case 'LOCAL':
            localStorage.clear();
            break;
          case 'SESSION':
            sessionStorage.clear();
            break;
        }
      }
  }